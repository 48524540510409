import React from "react";
import { Link } from "gatsby";
import { FaSearch } from "react-icons/fa"
import { SeoComponent } from "../components/SeoComponent";

const PageNotFound = () => {
  return (
    <div className="flex w-full flex-col mt-40 items-center">
        <FaSearch className="text-8xl font-bold mb-12"/>
      <div className="text-base mx-4 lg:text-4xl font-bold text-center">
        Üzgünüz; ziyaret etmek istediğiniz sayfa bulunamıyor.
      </div>
      <Link to="/">
        <button className="bg-pink-500 text-white  my-8 font-bold py-3 px-16">
          Anasayfaya Dön
        </button>
      </Link>
    </div>
  );
};

export default PageNotFound;

export const Head = () => (
  <SeoComponent />
);
